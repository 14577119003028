import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
//import TextField from '@mui/material/TextField';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
//import Link from '@mui/material/Link';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Switch from '@mui/material/Switch';

import Page from '../components/Page';
import Main from 'layouts/Main';
import { useTheme } from '@mui/material/styles';

const validationSchema = yup.object({
  currentPassword: yup.string().required('Please specify your password'),
  newPassword: yup
    .string()
    .required('Please specify your password')
    .min(8, 'The password should have at minimum length of 8'),
  repeatPassword: yup
    .string()
    .required('Please specify your password')
    .min(8, 'The password should have at minimum length of 8'),
});

const General = () => {
  const theme = useTheme();
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Main>
      <Page>
        <Box>
          <Box
            display={'flex'}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            
          >
            <Typography variant="h6">
              <Typography fontWeight={700}>
                           PARENT ORIENTATION AND TEACHER INTRODUCTION<br />
                
              </Typography>
              <Typography align="justify">
                <br />  The academic year 2021-22 saw new beginnings at National Public School, Yeshwanthpur, with extensive sessions of Parent Orientation conducted for all Classes from Mont-KG to Class-12. While the sessions for Classes 10 and 12 were held on 9 and 10 April, respectively, those for the others were held from 22 May to 31 May, concluding with sessions for the parents of tiny tots on 31 May. Organised with an intent to familiarise the parents with the curriculum and the assessment pattern for the new academic year, these sessions saw the active participation of parents on the online platform. The sessions set the tone for the forthcoming academic year and infused everyone with excitement, energy and vigour<br />
              </Typography>
              <Typography align="justify">

                <br />  The new faculty was formally inducted through a series of Teacher Induction Sessions which were interactive, informative and thoughtfully curated to meet the demands of the pedagogy of current times. They were enriching in terms of educational update, transformation and upholding the value system of the institution
              </Typography>
            </Typography>
            {/* <Button
              size={'large'}
              variant={'outlined'}
              sx={{ marginTop: { xs: 2, md: 0 } }}
            >
              Log out
            </Button> */}
          </Box>
          {/* <Box paddingY={4}>
            <Divider />
          </Box> */}
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Typography
                  variant={'subtitle2'}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Current password
                </Typography> */}
                {/* <TextField
                  variant="outlined"
                  name={'currentPassword'}
                  type={'password'}
                  fullWidth
                  value={formik.values.currentPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.currentPassword &&
                    Boolean(formik.errors.currentPassword)
                  }
                  helperText={
                    formik.touched.currentPassword &&
                    formik.errors.currentPassword
                  }
                /> */}
              </Grid>
              <Grid item xs={12}>
                <Grid item container justifyContent={'center'} xs={12} md={6}>
                  <Box height={1} width={1} maxWidth={500}>
                    <Box
                      component={'img'}
                      src={
                        'https://www.npsypr.com/gallery/gallery/1.%20Artwork%20Jpeg.jpg'
                      }
                      width={1}
                      height={1}
                      sx={{
                        filter:
                         theme.palette.mode === 'dark'
                           ? 'brightness(0.8)'
                           : 'none',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {/* <Typography
                  variant={'subtitle2'}
                  sx={{ marginBottom: 2 }}
                  fontWeight={700}
                >
                  Repeat password
                </Typography> */}
                {/* <TextField
                  variant="outlined"
                  name={'repeatPassword'}
                  type={'password'}
                  fullWidth
                  value={formik.values.repeatPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.repeatPassword &&
                    Boolean(formik.errors.repeatPassword)
                  }
                  helperText={
                    formik.touched.repeatPassword &&
                    formik.errors.repeatPassword
                  }
                /> */}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch color="primary" defaultChecked />}
                  label={
                    <Typography variant="subtitle1" fontWeight={700}>
                      Public Profile
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </Grid> */}
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch color="primary" />}
                  label={
                    <Typography variant="subtitle1" fontWeight={700}>
                      Expose your email
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </Grid> */}
              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ xs: 'stretched', sm: 'center' }}
                  justifyContent={'space-between'}
                  width={1}
                  margin={'0 auto'}
                >
                  {/* <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={'subtitle2'}>
                      You may also consider to update your{' '}
                      <Link
                        color={'primary'}
                        href={'/events-show-and-tell-activity'}
                        underline={'none'}
                      >
                        notification settings.
                      </Link>
                    </Typography>
                  </Box> */}
                  {/* <Button size={'large'} variant={'contained'} type={'submit'}>
                    Save
                  </Button> */}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Page>
    </Main>
  );
};

export default General;